import React, { useContext } from "react"
import { graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import { AppContext } from "../../context/appContext"

import Layout from "../../components/layout"
import SliceZone from "../../components/sliceZone"
import { PitchFooter, DeckNav, EmailMask } from "../../components/pitchDecks"
import PageTransition from "../../components/transitions/pageTransition"

const IS_BROWSER = typeof window !== "undefined"

const WhoTab = ({ data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const mergedData = mergePrismicPreviewData({ staticData, previewData })
  const { handlizeTitle } = useContext(AppContext)
  const { uid } = mergedData.prismicDigitalPitchDeck
  const {
    body1,
    users,
    show_tab_two,
    title_tab_two,
  } = mergedData.prismicDigitalPitchDeck.data

  return (
    <Layout>
      <EmailMask users={users}>
        <DeckNav uid={uid} {...mergedData.prismicDigitalPitchDeck.data} />
        <PageTransition>{body1 && <SliceZone slices={body1} />}</PageTransition>
        {show_tab_two === "true" ? (
          <PitchFooter
            nextLink={{
              path: `/${handlizeTitle(title_tab_two.text)}`,
              name: title_tab_two.text,
            }}
            prevLink={{ path: "/", name: "The brief", isFirst: true }}
            uid={uid}
          />
        ) : (
          <PitchFooter
            prevLink={{ path: "/", name: "The brief", isFirst: true }}
            uid={uid}
          />
        )}
      </EmailMask>
    </Layout>
  )
}

export default WhoTab

export const pageQuery = graphql`
  query whoBySlug($uid: String!) {
    prismicDigitalPitchDeck(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        users {
          email {
            text
          }
        }
        show_tab_one
        show_tab_two
        show_tab_three
        show_tab_four
        title_tab_one {
          text
        }
        title_tab_two {
          text
        }
        title_tab_four {
          text
        }
        title_tab_three {
          text
        }
        body1 {
          ... on PrismicDigitalPitchDeckBody1PageIntro {
            id
            slice_type
            primary {
              subheading {
                text
              }
              heading {
                text
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1TwoUpImage {
            id
            slice_type
            primary {
              small_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              large_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1Headline {
            id
            slice_type
            primary {
              text {
                html
              }
              size
            }
          }
          ... on PrismicDigitalPitchDeckBody1TextBlock {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1KeyRequirements {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              requirement {
                text
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1VideoTeaser {
            id
            slice_type
            primary {
              heading {
                text
              }
              video {
                url
              }
              link_text {
                text
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1ImageCarousel {
            id
            slice_type
            items {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              video {
                url
              }
              is_video
            }
          }
          ... on PrismicDigitalPitchDeckBody1TechStack {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
              grid_size
            }
            items {
              logo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              name {
                text
              }
              description {
                text
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1Services {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              service {
                text
              }
              description {
                html
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1StaffBios {
            id
            slice_type
            primary {
              heading {
                html
              }
            }
            items {
              bio {
                document {
                  ... on PrismicStaffBio {
                    id
                    data {
                      name {
                        text
                      }
                      position {
                        text
                      }
                      bio {
                        html
                      }
                      image {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 960, quality: 90) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1CaseStudies {
            id
            slice_type
            items {
              case_study {
                document {
                  ... on PrismicCaseStudy {
                    id
                    tags
                    data {
                      video {
                        url
                      }
                      logo {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 1800, quality: 90) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                      image {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 1800, quality: 90) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                      description {
                        html
                      }
                      reverse_text
                      background_color
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1Image {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              horizontal_wrap
            }
          }
          ... on PrismicDigitalPitchDeckBody1UiVideo {
            id
            slice_type
            primary {
              video {
                url
              }
              prototype_link {
                url
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1TextWithBackground {
            id
            slice_type
            primary {
              background_colour
              text_color
              text {
                html
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1ImageText {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              text {
                html
              }
              reverse_layout
            }
          }
          ... on PrismicDigitalPitchDeckBody1PricingTable {
            id
            slice_type
            primary {
              terms {
                html
              }
              intro {
                html
              }
            }
            items {
              line_title {
                text
              }
              line_price {
                text
              }
              sale_price {
                text
              }
              description {
                html
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1EnquiryPrompt {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
          }
          ... on PrismicDigitalPitchDeckBody1Spacer {
            id
            slice_type
            primary {
              size
            }
          }
          ... on PrismicDigitalPitchDeckBody1Statistics {
            id
            slice_type
            items {
              number
              prefix
              suffix
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`
